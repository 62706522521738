section[content="galleries__section"] {
    .img__container {
        position: relative;
        display: flex;

        img {
            height: 627px;
            object-fit: cover;
        }

        .img__text {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: xxx-large;
            color: white;
            font-weight: bold;
            display: none;
        }

        &:hover {
            .img__text {
                position: absolute;
                display: block;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            img{
                filter: brightness(0.5);
                transition: all ease-in-out 0.3s;
            }
        }
    }
}