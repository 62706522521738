@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');


body {
    font-family: 'Inter', sans-serif !important;
    // font-family: 'Poppins', sans-serif !important;
    // font-family: 'Playfair Display', serif !important;
    // font-family: 'Rubik', sans-serif !important;
    // font-family: 'Lora', serif !important;
}

$headings-font-family: 'Poppins', sans-serif;
$headings-font-weight: bolder;