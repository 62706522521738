section[content="home__carousel"] {
    div[dir="ltr"] {
        display: flex;
        position: relative;

        .react-slideshow-container {
            width: 100%;
        }
    }

    .react-slideshow-container+ul.indicators {
        margin-top: 0px;
        flex-direction: column;
        padding-left: 0px;
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: flex-end;

        li {
            width: 10px;
            height: 10px;
            padding: 6px;

            & button::before {
                width: 10px;
                height: 10px;
            }
        }
    }

    img {
        width: 100%;
        height: 84dvh;
        object-fit: cover;
    }
}