section[content="home__workshops"] {
    .workshops__subtitle {
        font-size: small;
        text-align: center;
        display: block;
    }

    .card {
        border-width: 0;

        img {
            border-radius: 0;
            opacity: 1;

        }

        &:hover img {
            animation: flash 1.5s;
        }

        @keyframes flash {
            0% {
                opacity: .5;
            }

            50% {
                opacity: 1;
            }
        }

        button {
            border-radius: 0;
        }

        @media screen and (max-width:992px) {
            margin-bottom: 3rem;
        }

    }

    .workshop__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width:992px) {
            .row {
                --bs-gutter-x: 3rem !important;
            }
        }
    }
}