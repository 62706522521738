section[content="home__gallery"] {
    span.gallery__subtitle{
        text-align: center;
        display: block;
        font-size: small;
    }

    h1 {
        text-align: center;
    }

    .gallery__image--container {
        .gallery__image--card {
            text-align: center;
            margin-bottom: 2rem;

            .image__card {
                position: relative;
                overflow: hidden;
                margin-bottom: 0.5rem;
            }

            img {
                height: 400px;
                width: 100%;
                object-fit: cover;
                transition: transform 0.3s ease-in-out;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

    }
    .view--more{
        text-decoration: underline;
        text-underline-offset: 5px;
    }
}