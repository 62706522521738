@import 'list';

.contact{
    span{
        display: block;
    }
    .title{
        font-weight: bold;
    }
}

.container-contact{
    .image-container img{
        height: 80dvh !important;
        object-fit: cover;
    }
}