section[data-content="blog"] {
    .bg-success-light {
        background-color: rgba(223, 223, 223, 0.733);
    }

    .content .card {
        border-width: 0;
        margin-bottom: 2.5rem;

        .card-img-top {
            height: 250px;
            width: 100%;
            object-fit: cover;
            transition: all ease 0.5s;

            &:hover {
                filter: saturate(1.5);
            }
        }
        .card-link{
            font-size: small;
        }
    }

    @media screen and (min-width:768px) {
        .content .card {
            flex-direction: row !important;

            .card-img-top {
                // width: 30% !important;
                // height: unset;
                object-fit: cover;
                border-top-right-radius: 0;
                border-bottom-left-radius: 5px;
            }
        }
    }

    .sidebar {
        .sidebar-categories {
            ul li {
                font-size: smaller;
                cursor: pointer;
            }
        }

        .sidebar-posts {
            .card-title {
                font-size: medium;
            }

            p {
                font-size: smaller;
            }
            .bg-info{
                background-color: #d3d3d3 !important;
            }
        }

        .sidebar-tags {
            .tags-heading {
                display: block;
            }

            .tags {
                padding: 0.5rem 1rem;
                // padding: 5px 15px 5px 10px;
                border-radius: 50px;
                margin-inline-end: 10px;
                font-size: small;
            }
        }

        .card {
            background-color: inherit !important;
            border-width: 0;
        }

        @media screen and (min-width:768px) {
            & {
                display: grid;
                column-gap: 2rem;
                grid-template-areas:
                    'input categories'
                    'tags tags'
                    'posts posts'
                ;
            }

            .sidebar-input {
                grid-area: input;
                width: 300px;
            }

            .sidebar-categories {
                grid-area: categories;
            }

            .sidebar-posts {
                grid-area: posts;
                margin-bottom: 0 !important;
            }

            .sidebar-tags {
                grid-area: tags;
            }
        }

    }

    @media screen and (min-width:992px) {
        & {
            position: relative;

            .blog-position {
                position: relative;
                display: flex;
            }

            .content {
                width: 65%;
                position: relative;

                .card {
                    margin-bottom: 1.5rem;
                }
            }

            .sidebar {
                width: 35%;
                position: sticky;
                display: flex;
                flex-direction: column;
                height: max-content !important;
                top: 82px;

                .card {
                    flex-direction: column !important;
                }
                .sidebar-input {
                    width: 100%;
                }
            }
        }
    }

    @media screen and (min-width:1440px) {
        .sidebar {
            width: 30%;
        }
    }
}