section[content="home__video"] {
    position: relative;
    display: flex;

    .container-fluid {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FFF;
        text-align: center;

        span {
            text-transform: uppercase;
        }

        h2 {
            font-size: xxx-large;

            @media screen and (max-width:576px) {
                font-size: x-large;
            }
        }
    }

    .video__subtitle {
        font-size: small;
    }
}