@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

footer {
    background: rgb(241, 239, 239);
    // margin-top: 3rem;
    padding: 1rem 0 1rem 0;

    .footer__quote {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-block: 2rem;
    }

    .footer__social {
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media screen and (max-width:992px) {
            flex-direction: column-reverse;
            .social__text{
                text-align: center;
                margin-top: 0.5rem;
            }
        }

        // margin: 1rem;

        .social__icon {
            i {
                padding: 1rem 1.5rem 0;
                margin: 0.5rem 0;
                font-size: 1.5rem;

                &:hover {
                    transform: scale(1.25);
                }
            }
        }
    }
}