header {
    a.logo {
        text-align: center;
        display: block;
        text-decoration: none;
        padding: 1rem 1rem 0rem 1rem;
        text-transform: uppercase;
        font-size: xx-large;
        font-weight: bolder;
        color: black;

        @media screen and (max-width:525px) {
            font-size: x-large;
        }

        &:visited {
            color: black;
        }
    }

    .header__social {
        ul {
            list-style: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media screen and (max-width:576px) {
                justify-content: center;
            }

            li {
                padding-inline-end: 4rem;

                @media screen and (max-width:576px) {
                    padding-inline-end: unset;
                    padding-inline: 0.8rem;
                    margin-top: 0.5rem;
                }

                i {
                    font-size: xx-large;

                    @media screen and (max-width:992px) {
                        font-size: x-large;
                    }
                }
            }
        }
    }

    .navbar-nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1rem;

        li {
            padding: 0.5rem 2rem;
            font-weight: bold;
        }

        a {
            color: black !important;
            margin-inline: 2rem;

            &:hover,
            &.nav-link.active {
                text-decoration: underline !important;
                text-underline-offset: 5px;
            }
        }
    }

    #navBar-btn:focus {
        box-shadow: none;
    }



    .navbar-toggler-icon {
        display: none;
    }

    .navbar-toggler {
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    .icon-bar {
        background-color: #000;
        width: 25px;
        height: 3px;
        margin: 4px 0;
        transition: 0.4s;
    }

    .open .icon-bar:nth-child(1) {
        transform: rotate(-45deg) translate(-4px, 6px);
    }

    .open .icon-bar:nth-child(2) {
        opacity: 0;
    }

    .open .icon-bar:nth-child(3) {
        transform: rotate(45deg) translate(-4px, -6px);
    }

    // nav:has(.show) {
    //     height: 90dvh;
    //     display: flex;
    //     flex-direction: column;
    //     flex-wrap: unset;

    //     .navbar-nav {
    //         height: 90dvh;
    //         justify-content: space-around;

    //         a {
    //             margin-bottom: 2.5rem;
    //             font-size: x-large;
    //         }
    //     }
    // }

    // nav:has(.collapsing) .navbar-collapse {
    //     display: none !important;
    // }

    @media screen and (max-width:767px) {
        display: flex;
        flex-direction: column;

        .logo_container {
            order: 1;
        }

        .header__social {
            order: 3;
            // display: none;
        }

        .nav_container {
            order: 2;
        }

        .nav_container a {
            margin-bottom: 1rem;
            font-size: x-large;
        }

        & .h-100dvh {
            height: 100dvh;

            // &.navbar-nav {
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: space-evenly !important;
            // }
        }
    }

}